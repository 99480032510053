/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */

// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';

// import icons
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faVolumeUp, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';

// add the imported icons to the library
library.add(faFacebookF, faInstagram, faLinkedinIn, faTwitter, faEnvelope, faVolumeUp, faVolumeXmark);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

import Glider from 'glider-js';
import 'modal-video/js/jquery-modal-video.js';
import 'isotope-layout/dist/isotope.pkgd.min';

export default {
  init() {
    if(('ontouchstart' in window)) {
      $('body').addClass('touch');
    } else {
      $('body').addClass('no-touch');
    }

    jQuery('body').on('click', 'a', function (e) {
      if (/#contact/.test(this.href)) {
          e.preventDefault();
          $('#contact-modal').foundation('open');
      }
    });

    $('.js-modal-yt').modalVideo({
      channel: 'youtube',
      youtube: {
        autoplay: 1,
        playsinline: 0,
        modestbranding: 1,
        enablejsapi: 1,
      },
    });

    $('.js-modal-vm').modalVideo({
      channel: 'vimeo',
      vimeo: {
        autoplay: 1,
        playsinline: false,
      },
    });

    $( '.js-modal-yt' ).on( 'click', function() {
      var video_title = $(this).attr('data-video-title');
  
      $('.modal-video-movie-wrap').append('<div class="modal-video-movie-title">'+video_title+'</div>');
    });  

    if( $('.content-info--locations').length ) {
      new Glider(document.querySelector('.content-info--locations'), {
        arrows: {
          prev: '.content-info--locations__nav--prev',
          next: '.content-info--locations__nav--next',
        },
        slidesToShow: 1.1,
        slidesToScroll: 0.8,
        draggable: true,
        responsive: [
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2.1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2,
            },
          },
        ],
      });
    }

    if($('.module--news_carousel__posts').length){
      new Glider(document.querySelector('.module--news_carousel__posts'), {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        draggable: true,
        arrows: {
          next: '.module--news_carousel__postScrollNext',
          prev: '.module--news_carousel__postScrollPrev',
        },
        responsive: [
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2.1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 3,
            },
          },
        ],
      });
    }

    $('#newsletter-panel').on('on.zf.toggler', function() {
      $('#newsletter-panel--toggler').addClass('hidden');
      $('#newsletter-panel').removeClass('inactive');
      var newsletter_height = $('#newsletter-panel > .grid-container').outerHeight();
      $('#newsletter-panel').css({'min-height':newsletter_height});
    });
    $('#newsletter-panel').on('off.zf.toggler', function() {
      $('#newsletter-panel--toggler').removeClass('hidden');
      $('#newsletter-panel').addClass('inactive');
    });

    $('#navigationModal').on('opened.zf.offcanvas', function() {
      $('.top_bar__mobile .hamburger, .top_bar__preschooltoggle .hamburger').addClass('is-active');
      $('#search-panel').removeClass('active');
    });

    $('#navigationModal').on('closed.zf.offcanvas', function() {
      $('.top_bar__mobile .hamburger, .top_bar__preschooltoggle .hamburger').removeClass('is-active');
    });

    $('.top_bar__mobile--search').on('click', function () {
      $('#navigationModal').foundation('close');
    });

    $(window).on('resize', function () {
      var newsletter_height = $('#newsletter-panel > .grid-container').outerHeight();
      $('#newsletter-panel').css({'min-height':newsletter_height});

      var top_bar_height = $('.top_bar').outerHeight();
      $('#mobile-primary-menu .intro').css({'padding-top':top_bar_height});
    }).resize();

    $(document).bind('gform_post_render', function(){
      var newsletter_height = $('#newsletter-panel > .grid-container').outerHeight();
      $('#newsletter-panel').css({'min-height':newsletter_height});
      $('#newsletter-panel').addClass('locked');

      setTimeout(function(){
        $('#newsletter-panel').removeClass('locked');
      }, 500);
    });

    $(document).on('gform_confirmation_loaded', function(){
      var newsletter_height = $('#newsletter-panel > .grid-container').outerHeight();
      $('#newsletter-panel').css({'min-height':newsletter_height});
    });

    $('.alert-banner .close-button').on('click', function () {
      setTimeout(function(){
        var top_bar_height = $('.top_bar').outerHeight();
        $('#mobile-primary-menu .intro').css({'padding-top':top_bar_height});
      }, 450);
    });

    $(window).resize(function(){
      if ($(window).width() > 1024){	
        $('#navigationModal').foundation('close');
        $('.top_bar__mobile .hamburger').removeClass('is-active');
      }	
    });

    $('.module--interactive_cta__links li').on('mouseover', function () {
      var link_id = $(this).attr('data-id');
      $('.module--interactive_cta__link').removeClass('active');
      $('.module--interactive_cta__background').removeClass('active');
      $('#'+link_id).addClass('active');
      $('.module--interactive_cta__links li').removeClass('active');
      $(this).addClass('active');
    });

    $('.module--interactive_cta__container').on('mouseleave', function () {
      $('.module--interactive_cta__link').removeClass('active');
      $('.module--interactive_cta__background').addClass('active');
      $('.module--interactive_cta__links li').removeClass('active');
    });

    $(document).on('mouseover', '.module--locations__id', function() {
      var location_id = $(this).attr('data-id');
      $('#location-' + location_id).trigger( 'click' );
    });

    jQuery.fn.scrollTo = function(elem, speed) { 
      $(this).animate({
          scrollTop:  $(this).scrollTop() - $(this).offset().top + $(elem).offset().top,
      }, speed == undefined ? 1000 : speed); 
      return this; 
    };

    $('.module--locations__scroll.forward').on('click', function() {
      $('.table-scroll').animate({scrollLeft: $('#scroll').position().left}, 1000);
    });

    $('.module--locations__scroll.back').on('click', function() {
      $('.table-scroll').animate({scrollLeft: 0}, 1000);
    });

    $('.table-scroll').scroll(function() {
      var width = $(this).width();
      var left = $(this).scrollLeft();

      if (width - left > width*0.9) {
        $('.module--locations__scroll.forward').removeClass('hidden');
        $('.module--locations__scroll.back').addClass('hidden');
      } else {
        $('.module--locations__scroll.forward').addClass('hidden');
        $('.module--locations__scroll.back').removeClass('hidden');
      }
    });

    if( $('.news_grid--masonry').length ) {
      var newsOptions = {
        itemSelector: '.module__masonry_cta_grid--item',
        transitionDuration: '0',
        masonry: {
            horizontalOrder: true,
        },
      };
      $('.news_grid--masonry').isotope( newsOptions );
    }

    if( $('.module__masonry_cta_grid--masonry').length ) {
      var isoOptions = {
        itemSelector: '.module__masonry_cta_grid--item',
        transitionDuration: '0',
        masonry: {
            horizontalOrder: true,
        },
      };

      var $masonry_glider = new Glider(document.querySelector('.module__masonry_cta_grid--masonry'), {
        arrows: {
          prev: '.glider-prev',
          next: '.glider-next',
        },
        slidesToShow: 1.2,
        slidesToScroll: 1,
        draggable: false,
      });

      var isActive = true;
      var initOnce = true;

      var destroyOnce = false;

      $(window).on('load resize scroll', function() {
        var win = $(this); //this = window

        if (win.width() <= 640) {
          isActive = true;
        } else {
          isActive = false;
        }

        if ( isActive ) {
          if(destroyOnce) {
            $('.module__masonry_cta_grid--masonry').isotope('destroy'); // destroy

            $masonry_glider = new Glider(document.querySelector('.module__masonry_cta_grid--masonry'), {
              arrows: {
                prev: '.glider-prev',
                next: '.glider-next',
              },
              slidesToShow: 1.2,
              slidesToScroll: 1,
              draggable: false,
            });

            destroyOnce = false;
            initOnce = true;
          }
        } else {
          if(initOnce) {
            $masonry_glider.destroy();
            $('.module__masonry_cta_grid--masonry').isotope( isoOptions );
            destroyOnce = true;
            initOnce = false;
          }
        }

        isActive = !isActive;
      });

    }

    if( $('.glider-events-mobile .module--upcoming_events__posts').length ) {
      var $events_glider_mobile = new Glider(document.querySelector('.glider-events-mobile .module--upcoming_events__posts'), {
        arrows: {
          prev: '.module--upcoming_events__posts__nav--prev',
          next: '.module--upcoming_events__posts__nav--next',
        },
        slidesToShow: 1.25,
        slidesToScroll: 1,
        draggable: false,
      });

      console.log('Init Events');

      var eventsIsActive = true;
      var eventsInitOnce = true;

      var eventsDestroyOnce = false;

      $(window).on('load resize scroll', function() {
        var win = $(this); //this = window

        if (win.width() <= 640) {
          eventsIsActive = true;
        } else {
          eventsIsActive = false;
        }

        if ( eventsIsActive ) {
          if(eventsDestroyOnce) {
            $events_glider_mobile = new Glider(document.querySelector('.glider-events-mobile .module--upcoming_events__posts'), {
              arrows: {
                prev: '.module--upcoming_events__posts__nav--prev',
                next: '.module--upcoming_events__posts__nav--next',
              },
              slidesToShow: 1.25,
              slidesToScroll: 1,
              draggable: false,
            });

            eventsDestroyOnce = false;
            eventsInitOnce = true;
          }
        } else {
          if(eventsInitOnce) {
            $events_glider_mobile.destroy();
            eventsDestroyOnce = true;
            eventsInitOnce = false;
          }
        }

        eventsIsActive = !eventsIsActive;
      });

    }

    if( $('.glider-events .module--upcoming_events__posts').length ) {
      var $events_glider = new Glider(document.querySelector('.glider-events .module--upcoming_events__posts'), {
        arrows: {
          prev: '.module--upcoming_events__posts__nav--prev',
          next: '.module--upcoming_events__posts__nav--next',
        },
        slidesToShow: 1.25,
        slidesToScroll: 1,
        draggable: false,
        responsive: [
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3.325,
              slidesToScroll: 3,
            },
          },
        ],
      });
    }

    if( $('.module--homeintro__cards').length ) {
      var $intro_glider = new Glider(document.querySelector('.module--homeintro__glider'), {
        arrows: {
          prev: '.glider-prev',
          next: '.glider-next',
        },
        slidesToShow: 1.25,
        slidesToScroll: 1,
        draggable: false,
      });

      var introIsActive = true;
      var introInitOnce = true;

      var introDestroyOnce = false;

      $(window).on('load resize scroll', function() {
        var win = $(this); //this = window

        if (win.width() <= 640) {
          introIsActive = true;
        } else {
          introIsActive = false;
        }

        if ( introIsActive ) {
          if(introDestroyOnce) {
            $intro_glider = new Glider(document.querySelector('.module--homeintro__glider'), {
              arrows: {
                prev: '.glider-prev',
                next: '.glider-next',
              },
              slidesToShow: 1.25,
              slidesToScroll: 1,
              draggable: false,
            });

            introDestroyOnce = false;
            introInitOnce = true;
          }
        } else {
          if(introInitOnce) {
            $intro_glider.destroy();
            introDestroyOnce = true;
            introInitOnce = false;
          }
        }

        introIsActive = !introIsActive;
      });

    }

    $('.module__equipment--item.image-tip').tooltipster({
      theme: ['tooltipster-noir', 'tooltipster-noir-customized'],
      delay: 0,
      arrow: false,
      trigger: 'custom',
      delayTouch: [100, 300],
      maxWidth: 400,
      animationDuration: 0,
      triggerOpen: {
        mouseenter: true,
        touchstart: true,
      },
      triggerClose: {
          mouseleave: true,
          click: true,
          scroll: true,
          tap: true,
      },
    });
    $('.searchandfilter .cat-item input:checkbox').on('change',function(){
      $(this).closest('form').submit();
    });

    setTimeout( function(){ 
        $('#ListCalClasses div > a').each(function () {
            var classLink = $(this).attr('href');
            var classOpenFull = $(this).find('.L1').find('span').attr('class');
            //alert(classOpenFull);
            //alert(classLink);
            if (classOpenFull == 'openClass') {
                $(this).find('.L1').append('<a href="'+classLink+'" target="_blank" class="w-btn style_solid color_primary icon_none '+classOpenFull+' desktop-class">Register and Enroll</a>');
                $(this).find('.L4').append('<a href="'+classLink+'" target="_blank" class="w-btn style_solid color_primary icon_none '+classOpenFull+' mobile-class">Register and Enroll</a>');
            }else{
                $(this).find('.L1').append('<a href="'+classLink+'" target="_blank" class="w-btn style_solid color_primary icon_none '+classOpenFull+' desktop-class">full, join wait list</a>');
                $(this).find('.L4').append('<a href="'+classLink+'" target="_blank" class="w-btn style_solid color_primary icon_none '+classOpenFull+' mobile-class">full, join wait list</a>');
            }
        });
    }, 1000 );

    $( '#age_2_5' ).on( 'click', function() {
      if ($(this).prop('checked')==true){ 
        $('#age_2_0').prop( 'checked', true );
      }
    });

    $( '#age_3_5' ).on( 'click', function() {
      if ($(this).prop('checked')==true){ 
        $('#age_3_0').prop( 'checked', true );
      }
    });

    function update_filter() {
      var season = '';
      var schedulelocation = encodeURIComponent($('#schedulelocation input:checked' ).val());
      var search = encodeURIComponent($('#classsearch' ).val());
      var time = $('#classtime input:checked' ).val();
      var avail = $('#classavailability input:checked' ).val();
      /* var sort1 = $('#SORT1').val(); */
      /* var sort2 = $('#SORT2').val(); */
      /* var sort3 = $('#SORT3').val(); */
      var sort1 = 1;
      var sort2 = 3;
      var sort3 = 4;
      var days_checked = [];
      $.each($('#day input:checked'), function(){
        days_checked.push($(this).val());
      });
      var day = days_checked.join(',');

      var age_checked = [];
      $.each($('#age input:checked'), function(){
        age_checked.push($(this).val());
      });
      var age = age_checked.join(',');
      if($('#scheduleseason').length ) {
        season = $('#scheduleseason input:checked' ).val();
      } else {
        season = $('.module__class_schedule').data('season');
      }
      season = encodeURIComponent(season);
      var url = '?embed=&search=&jump=true&season='+season+'&time='+time+'&classavailability='+avail+'&classage='+age+'&classday='+day+'&location='+schedulelocation+'&CLASS_NAME='+search+'&SORT1='+sort1+'&SORT2='+sort2+'&SORT3='+sort3;
      window.location.href = window.location.href.replace( /[\?#].*|$/, url );
    }

    $('.filterby').on('change click',function(){
      update_filter();
    });

    $('.sortby').on('change',function(){
      update_filter();
    });

    $('#classsearch').on('keyup', function (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        update_filter();
      }
    })

    $('.module__class_schedule--landingLink').on('click', function() {
      var location_id = $(this).data('location');
      var season = $(this).data('season');
      var url = '?embed=&search=&location='+location_id+'&classday=1,2,4,8,16,32,64&season='+season;
      window.location.href = window.location.href.replace( /[\?#].*|$/, url );
    });

    $(document).on('click', '#SDCalendar .Btn:not(.Btn__self)', function(event) { 
      $(this).attr('target', '_blank');
    });

    $('#day .toggle').on('click', function() { 
      $('#day .all-toggle').prop('checked', false);
    });

    $('#day .all-toggle').on('click', function() { 
      $('#day .toggle').prop('checked', false);
    });

    $('#age .toggle').on('click', function() { 
      $('#age .all-toggle').prop('checked', false);
    });

    $('#age .all-toggle').on('click', function() { 
      $('#age .toggle').prop('checked', false);
    });

    function addDotButtonText() {
      // loop through each dot element
      $('.owl-dot').each(function() {
          // remove old text nodes
          $(this).find('.offscreen').remove();
  
          // grab its (zero-based) order in the series
          let idx = $(this).index() + 1;
  
          // append a span to the button containing descriptive text
          $(this).append('<span class="offscreen">Go to slide ' + idx + '</span>');
      });
    }

    /* $("#gender.filterby").change(function(){
        var filters = $.map($("#gender.filterby").toArray(), function(e){
            return $(e).val();
        }).join(".");
        $(".classes").find(".class").hide();
        $(".classes").find(".class." + filters).show();
    });*/

    $(function() {
      $('a[href*=\\#]').on('click', function(event) {
        if( this.pathname === window.location.pathname ){
          event.preventDefault();
          var anchor_id = $(this).prop('hash').replace(/^#/, '');
    
          setTimeout(function() {
            document.getElementById(anchor_id).scrollIntoView();
          }, 250);
        }
      });
    });

    $(function() {
      $('a[href*=\\#]').on('click', function() {
        if( this.pathname === window.location.pathname ){
          $('#navigationModal').foundation('close');
        }
      });
    });

    $(function() {
      $('.accordion-content a[href*=\\#]').on('click', function() {
        var dataTarget = $(this).attr('href');
        var hash = dataTarget.substring(dataTarget.indexOf('#')+1);
        console.log(hash);
        $('.accordion').foundation('down', $('[id="' + hash + '"]'));
      });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
